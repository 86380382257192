import Loading from "./components/Loading";
import Room from "./components/Room";
import useBranch from "./hooks/useBranch";

export function App() {
  const branch = useBranch();

  return (
    <main className="flex min-h-screen flex-col items-center justify-center bg-gray-100 relative">
      {branch ? (
        <Room unityBranch={branch} unityCompression="brotli" />
      ) : (
        <Loading percentage={0} />
      )}
    </main>
  );
}
