import useIsInDiscord from "./useIsInDiscord";

const useUrlGenerator = ({ path }: { path: string }) => {
  const isInDiscord = useIsInDiscord();
  return isInDiscord ? `/.proxy/${path}` : `/${path}`;
};

export const useUrlGeneratorArray = (paths: string[]) => {
  const isInDiscord = useIsInDiscord();
  return paths.map((p) => (isInDiscord ? `/.proxy/${p}` : `/${p}`));
};

export const useUrlGeneratorObject = (paths: Record<string, string>) => {
  const isInDiscord = useIsInDiscord();
  return Object.fromEntries(
    Object.entries(paths).map(([key, value]) => [key, isInDiscord ? `/.proxy/${value}` : `/${value}`]),
  );
};

export default useUrlGenerator;
