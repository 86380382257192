import { useEffect, useRef } from "react";
import { useEffectOnce } from "usehooks-ts";

interface Props {
  url: string;
  onOpen?: (event: Event) => void;
  onClose?: (event: CloseEvent) => void;
  onMessage?: (event: MessageEvent) => void;
  onError?: (event: Event) => void;
  autoReconnect?: boolean;
}

/**
 * This hook creates a websocket connection and closes it when the component unmounts.
 */
const useWebsocket = ({
  url,
  onOpen = () => console.log(`websocket connected to ${url}`),
  onClose = () => console.log(`websocket disconnected from ${url}`),
  onMessage = () => {},
  onError = () => {},
  autoReconnect = true,
}: Props) => {
  const ws = useRef<WebSocket>(null);

  const connect = () => {
    ws.current = new WebSocket(url);
    ws.current.onopen = onOpen;
    ws.current.onclose = onClose;
    ws.current.onmessage = onMessage;
    ws.current.onerror = onError;
  };

  // TODO: handle detecting when the websocket is closed from the other side and reconnecting
  useEffectOnce(() => {
    connect();

    // since ws.current is mutable, we need to store it in a const to be sure we close the right websocket later
    const currentWs = ws.current;
    return () => currentWs.close();
  });

  useEffect(() => {
    if (!ws.current) return;
    if (ws.current.readyState === WebSocket.CLOSED && autoReconnect) {
      const timeout = setTimeout(connect, 1000);
      return () => clearTimeout(timeout);
    }
  }, [ws.current?.readyState]);

  return ws.current;
};

export default useWebsocket;
