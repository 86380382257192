import { useCallback, useEffect, useState } from "react";
import useUrlGenerator from "./useUrlGenerator";

const useBranch = () => {
  const [branch, setBranch] = useState<string>(null);
  const branchUrl = useUrlGenerator({ path: "api/branch-build" });

  const fetchBranch = useCallback(async () => {
    const response = await (await fetch(branchUrl)).json();
    if (response.branch != null) {
      setBranch(response.branch);
    }
  }, []);

  useEffect(() => {
    fetchBranch();
  }, []);

  return branch;
};

export default useBranch;
