interface LoadingProps {
  percentage: number;
}

const Loading = ({ percentage }: LoadingProps) => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-slate-600">
      <span>Loading... ({percentage}%)</span>
    </div>
  );
};

export default Loading;
